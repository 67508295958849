import type { PropsWithChildren, SyntheticEvent } from 'react';
import React from 'react';
import { createPortal } from 'react-dom';

import * as s from './ConfirmModal.styled';

interface Props {
  onClose: () => void;
  inPortal?: boolean;
}

export const ConfirmModal: React.FC<PropsWithChildren<Props>> = ({ onClose, children, inPortal = false }) => {
  const handleChildrenClick = (e: SyntheticEvent<HTMLDivElement>) => {
    e.stopPropagation();
  };

  const modal = (
    <s.Container onClick={onClose}>
      <s.Wrapper onClick={handleChildrenClick}>{children}</s.Wrapper>
    </s.Container>
  );

  return inPortal ? createPortal(modal, document.body) : modal;
};
