import styled from 'styled-components';

export const Container = styled.div`
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  position: fixed;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(6px);
`;

export const Wrapper = styled.div`
  width: 375px;
  height: auto;
  padding: 32px;
  margin: 0 16px;
  border-radius: 10px;
  background: white;
`;
